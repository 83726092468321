@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5f7;
  background-image: radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
  background-size: 10px 10px;
  font-family: 'Poppins', sans-serif;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.illustration img {
  max-height: 500px;
  width: auto;
}

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrapper p {
  font-size: 0.85rem;
  margin-top: 1rem;
}

.form {
  padding: 1.5rem;
  -ms-flex-preferred-size: 100vw;
  flex-basis: 100vw;
}

.form .heading {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.or {
  margin: 1rem 0;
}

.form label {
  display: block;
  margin: 1.25rem 0 1rem 0;
}

.form input {
  height: 40px;
  width: 100%;
  padding: 15px;
  background-color: #f1f9ff;
  border: 2px solid #bce0fd;
  border-radius: 8px;
}

.form button {
  height: 40px;
  width: 100%;
  background-color: #258de6;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  display: block;
  margin: 0 auto;
  font-weight: bold;
  margin-top: 1.5rem;
  border-radius: 8px;
}

.illustration {
  background-color: #ff5252;
  width: 50%;
  height: 100vh;
}

@media (min-width: 542px) {
  /* body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  } */
  .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 1.5rem;
    max-width: 1100px;
  }
  .form {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .form input {
    width: 250px;
  }
  .illustration img {
    max-width: 80%;
    height: auto;
  }
}

@media (max-width: 680px) {
  .illustration {
    display: none;
  }
}

.signUp .illustration {
  order: 2;
  justify-self: flex-end;
  margin-left: 2rem;
}

button:hover {
  filter: brightness(95%);
}

button:active {
  transform: scale(0.98);
}

.carouselCustom {
  border-radius: inherit;
}

.landingBackground {
  /* opacity: 0.4; */
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
  background-size: 10px 10px;
  background-repeat: no-repeat;
  z-index: -999;
}

.course {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 100px;
  
}

.course h1 {
  font-size: 36px;
  font-weight: 600;
}

.course p {
  color: #777;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  padding: 10px;
}

.row {
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
}
.course-col {
  flex-basis: 31%;
  background: #d0dff7;
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 20px 12px;
  box-sizing: border-box;
  transition: 0.5s;
}
.course h3 {
  text-align: center;
  font-weight: 600;
  margin: 10px;
}
.course-col:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

@media(max-width: 700px){
  .row {
      flex-direction: column;
  }
}

.campus {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 50px;
}
.campus-col {
  flex-basis: 32%;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.campus-col img {
  width: 100%;
}
.layer {
  background:transparent;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}
.layer:hover {
  background: rgba(226, 0, 0, 0.7);
}
.layer h3 {
  width: 100%;
  font-weight: 500;
  color: white;
  font-size: 26px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  opacity: 0;
  transition: 0.5s;
}
.layer:hover h3 {
  bottom: 49%;
  opacity: 1;
}

.testimonials {
  width: 80%;
  margin: auto;
  padding-top: 50px;
  text-align: center;
}
.testimonial-col {
  flex-basis: 44%;
  border-radius: 10px;
  margin-bottom: 5%;
  text-align: left;
  background: #d0dff7;
  cursor: pointer;
  display: flex;
  padding: 10px;
}
.testimonial-col img {
  height: 40px;
  margin-left: 5px;
  margin-right: 30px;
  border-radius: 50%; /* border radius = 50% means that the image will be circle*/
}
.testimonial-col p {
  padding: 0;
}
.testimonial-col h3 {
  margin-top: 15px;
  text-align: left;
}
.testimonial-col .fa {
  color: #f44336;
}
@media(max-width: 700px) {
  .testimonial-col img {
      margin-left: 0px;
      margin-right: 15px;
  }
}